import "../css/global.css"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"

const green = "text-teal-500"
const blue = "text-blue-500"

const Href = ({ data }) => {
  return (
    <span
      onClick={() => window.open(data.url, "_blank")}
      className={`cursor-pointer ${blue}`}
    >
      {data.text}
    </span>
  )
}

const Cards = ({ data }) => {
  let d = [
    {
      title: "Web Development",
      desc: (
        <div>
          We build websites customized for ANY scenario using popular Javascript
          frameworks such as{" "}
          <Href data={{ text: "Vue", url: "https://vuejs.org" }} /> and{" "}
          <Href data={{ text: "React", url: "https://reactjs.org" }} />.
        </div>
      ),
      img: "webDev",
    },
    {
      title: "App Development",
      desc: (
        <div>
          Superior experiences on smart devices with Native iOS, Android, or
          even Cross Platform development with{" "}
          <Href data={{ text: "Flutter", url: "https://flutter.dev" }} />.
        </div>
      ),
      img: "appDev",
    },
    {
      title: "Games",
      desc: (
        <div>
          Superior game developer. We are alumnis of world class gaming
          companies such as{" "}
          <Href data={{ text: "King", url: "https://king.com" }} /> and{" "}
          <Href data={{ text: "Gameloft", url: "https://www.gameloft.com" }} />.
          We are really good at building complex games utilizing some lingua
          franca mobile game engines out there such as Unity, Defold, Corona,
          etc.
        </div>
      ),
      img: "gameDev",
    },
    {
      title: "IoT",
      desc: (
        <div>
          We are very passionate about Internet of Things. We have developed
          variety of services for our clients ranging from Attendance Tracking
          utilizing Raspberry Pi to Improving sales performance with Proximity
          marketing using Beacon technology.
        </div>
      ),
      img: "iotDev",
    },
  ]
  let items = []
  d.forEach((item, i) => {
    items.push(
      <div
        className={`w-full lg:w-1/2 bg-black p-1 mt-3 mb-10 md:mt-0`}
        key={i}
      >
        <Img
          fluid={data[item.img].childImageSharp.fluid}
          style={{ height: "360px" }}
        />
        <div className="mt-2">
          <div className={`font-bold text-lg text-white ${green}`}>
            {item.title}
          </div>
          <div className={`text-gray-400 text-justify`}>{item.desc}</div>
        </div>
      </div>
    )
  })
  return (
    <div className="md:flex flex-wrap justify-center px-3 md:px-10 pt-3">
      {items}
    </div>
  )
}

const Clients = ({ data }) => {
  let d = [
    { img: "balita", url: "https://balita.co" },
    { img: "regents", url: "https://www.regentsschoolbali.com/" },
    { img: "inobu", url: "https://inobu.org" },
    { img: "beansprites", url: "http://beansprites.com" },
  ]
  let items = []
  d.forEach((item, i) => {
    items.push(
      <div
        onClick={() => window.open(item.url, "_blank")}
        className={`p-1 cursor-pointer flex items-center justify-center border border-gray-200`}
        style={{ width: "180px", height: "180px", opacity: "0.8" }}
        key={i}
      >
        <Img fixed={data[item.img].childImageSharp.fixed} />
      </div>
    )
  })
  return (
    <div className="flex flex-wrap justify-center md:px-10 pt-3">{items}</div>
  )
}

export const query = graphql`
  query {
    webDev: file(relativePath: { eq: "web-development.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gameDev: file(relativePath: { eq: "game-development.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appDev: file(relativePath: { eq: "app-development.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iotDev: file(relativePath: { eq: "iot-development.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    regents: file(relativePath: { eq: "regents-school-bali.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    balita: file(relativePath: { eq: "balitaco.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    inobu: file(relativePath: { eq: "inobu.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    beansprites: file(relativePath: { eq: "beansprites.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

class Expertise extends React.Component {
  render() {
    return (
      <div>
        <Header page="expertise" />
        <div className="bg-black">
          <div className="flex items-center justify-center flex-col">
            <div className="mt-3 flex items-center justify-center flex-col mb-8 mt-20 text-white p-2">
              <div className="text-3xl md:text-4xl">
                Full stack <span className={`${green}`}>digital</span> services
              </div>
              <div className="text-gray-400 text-sm md:text-lg text-center md:w-3/4">
                If you need a business analyst to translate your requirements
                into tech speak, UI to polish your app, or a developer to build
                your apps, website, or backend, here at{" "}
                <span className={`${green}`}>Digitify</span> we can provide all
                of these services and more. We’re passionate about refining your
                ideas to life as if they were our own creation.
              </div>
            </div>
          </div>
          <Cards data={this.props.data} />
          <div className="p-10"></div>
        </div>
        <div className="flex flex-col items-center justify-center md:px-10 py-10 bg-white">
          <div className={`text-2xl mb-10 ${green}`}>Our Clients</div>
          <Clients data={this.props.data} />
        </div>
        <Footer />
      </div>
    )
  }
}

export default Expertise
